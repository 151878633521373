<template>
  <svg width="10px" height="6px" viewBox="0 0 10 6" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
        <g transform="translate(-155.000000, -20.000000)" stroke="#353535" stroke-width="2">
            <g>
                <g transform="translate(156.000000, 21.000000)">
                    <polyline points="0 0 4 4 8 0"></polyline>
                </g>
            </g>
        </g>
    </g>
</svg>
</template>

<script>
export default {
  name: 'ArrDown'
}
</script>

